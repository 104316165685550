import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import css from "./header.module.css";
import MenuMobile from "./menuMobile/MenuMobile";
import ModalFaleConnosco from "../../modal/ModalFaleConnosco";
import EasyBuy from "./EasyBuy";
import { Link } from "react-router-dom";
import NavBarCollapse from "./menuDesktop/NavBarCollapse";
import { ReactComponent as Hamburger } from "../../../assets/icons/menu/menu.svg";
import { ReactComponent as Contact } from "../../../assets/icons/menu/falar_agora.svg";
import MenuBottom from "./menuBottom/MenuBottom";
import useMediaQuery from "../../hooks/useMediaQuery ";
import { CSSTransition } from "react-transition-group";
import { useLocation } from "react-router";

const logo = "/assets/img/logo.png";

export default function TopNav({
  allinfo,
  infoPrincipal,
  allListaCarros,
  allListaComercias,
  allListaClassicos,
  allListaMotas,
  path,
  viatura,
  ficha,
  infoJson,
  allinfoText,
}) {
  const location = useLocation();
  const nodeRef = React.useRef(null);
  const [disabled, setDisabled] = useState(false);
  const ismobile = useMediaQuery("(max-width: 991px)");
  const [noBlur, setNoBlur] = useState(`${css.bgNoBlur}`);
  const [modalFaleConnosco, setModalFaleConnosco] = useState(false);
  const [show, setShow] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [addClass, setAddClass] = useState(
    (location.pathname === "/" ||
      location.pathname === "/comerciais/" ||
      location.pathname === "/automoveis/") &&
      infoJson.Layout.Transparente
      ? "navbar-scroll-custom-visivel"
      : ""
  );
  const [addClassIn, setAddClassIn] = useState(
    (location.pathname === "/" ||
      location.pathname === "/comerciais/" ||
      location.pathname === "/automoveis/") &&
      infoJson.Layout.Transparente
      ? ""
      : css.logoIn
  );
  const [addClassOut, setAddClassOut] = useState(
    (location.pathname === "/" ||
      location.pathname === "/comerciais/" ||
      location.pathname === "/automoveis/") &&
      infoJson.Layout.Transparente
      ? css.logoOut
      : ""
  );

  useEffect(() => {
    (location.pathname === "/" ||
      location.pathname === "/comerciais/" ||
      location.pathname === "/automoveis/") &&
    infoJson.Layout.Transparente
      ? setAddClassOut(css.logoOut)
      : setAddClassOut("");
    (location.pathname === "/" ||
      location.pathname === "/comerciais/" ||
      location.pathname === "/automoveis/") &&
    infoJson.Layout.Transparente
      ? setAddClassIn("")
      : setAddClassIn(css.logoIn);
    (location.pathname === "/" ||
      location.pathname === "/comerciais/" ||
      location.pathname === "/automoveis/") &&
    infoJson.Layout.Transparente
      ? setAddClass("navbar-scroll-custom-visivel")
      : setAddClass("");
  }, [location.pathname, infoJson.Layout.Transparente]);

  useEffect(() => {
    //bloqueia o body
    //para não haver scroll na pagina
    modalFaleConnosco
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [modalFaleConnosco]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const handleClose = () => setShow(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > lastScrollTop && offset > 20) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
    if (
      (location.pathname === "/" ||
        location.pathname === "/automoveis/" ||
        location.pathname === "/comerciais/") &&
      infoJson.Layout.Transparente
    ) {
      if (offset > 100) {
        setNoBlur("");
        setAddClass("");
        setAddClassIn(css.logoIn);
        setAddClassOut("");
      } else {
        setAddClass("navbar-scroll-custom-visivel");
        setAddClassIn("");
        setNoBlur(`${css.bgNoBlur}`);
        setAddClassOut(css.logoOut);
      }
    }

    setLastScrollTop(offset);
  };

  return (
    <>
      {allinfo.length !== 0 && (
        <MenuMobile
          allinfo={infoPrincipal[0]}
          allListaCarros={allListaCarros}
          allListaComercias={allListaComercias}
          allListaClassicos={allListaClassicos}
          allListaMotas={allListaMotas}
          show={show}
          onHide={handleClose}
          infoJson={infoJson}
          allinfoText={allinfoText}
        />
      )}

      <Navbar
        className={
          isScroll
            ? ` ${css.cor_fundo_claro} corFundoClaro ${css.navbarScrollCustom} ${addClass} ${noBlur}`
            : ` ${css.cor_fundo_claro} corFundoClaro ${addClass} ${noBlur}`
        }
        fixed="top"
        expand="lg"
      >
        <Container fluid="xxxl">
          <div
            className={`menuMobile ${css.boxMenu}`}
            onClick={() => setShow(true)}
          >
            <Hamburger className={`${css.hamburger} p-0`} />
            Menu
          </div>

          {infoJson.Layout.Transparente &&
          (location.pathname === "/" ||
            location.pathname === "/automoveis/" ||
            location.pathname === "/comerciais/") ? (
            <Navbar.Brand
              as={Link}
              to="/"
              className={` ${addClassIn} logoTranparent `}
            >
              <img
                src={logo}
                className={` ${css.navbarBrandAlt}`}
                alt={infoJson.Stand.Nome}
              />
            </Navbar.Brand>
          ) : (
            <></>
          )}

          <Navbar.Brand
            as={Link}
            to="/"
            className={` m-auto ${addClassOut} ${css.navbarBrand}`}
          >
            <img
              src={logo}
              className={`${css.logo_topo2} ${css.navbarBrandAlt}`}
              alt={infoJson.Stand.Nome}
            />
          </Navbar.Brand>

          <div
            className={`menuMobileCantact d-lg-none ${css.boxMenu} ${
              modalFaleConnosco ? "active" : ""
            }`}
            onClick={() => setModalFaleConnosco(!modalFaleConnosco)}
          >
            <Contact height={"25px"} width={"25px"} className=" p-0 mb-2 " />
            Falar agora
          </div>
          <NavBarCollapse
            infoJson={infoJson}
            allListaCarros={allListaCarros}
            allListaComercias={allListaComercias}
            allListaClassicos={allListaClassicos}
            allListaMotas={allListaMotas}
            allinfoText={allinfoText}
          />
        </Container>
      </Navbar>

      <CSSTransition
        nodeRef={nodeRef}
        in={allinfo.length !== 0 && modalFaleConnosco && ismobile}
        unmountOnExit
        timeout={100}
        classNames={{
          enterActive: `${css.opcoesEnter}`,
          enterDone: `${css.opcoesEnterActive}`,
          exit: `${css.opcoesExit}`,
          exitActive: `${css.opcoesExitActive}`,
        }}
      >
        <ModalFaleConnosco
          show={modalFaleConnosco}
          onHide={() => {
            setModalFaleConnosco(false);
            setDisabled(false);
          }}
          allinfo={allinfo}
          infoJson={infoJson}
          nodeRef={nodeRef}
        />
      </CSSTransition>

      {infoJson.Compramos.easybuy.existe && infoJson.Compramos.easybuy.icon && (
        <EasyBuy infoJson={infoJson} />
      )}

      {ismobile && (
        <>
          <MenuBottom
            infoJson={infoJson}
            allListaCarros={allListaCarros}
            allListaComercias={allListaComercias}
            allListaClassicos={allListaClassicos}
            allListaMotas={allListaMotas}
            isScroll={isScroll}
            addClass={addClass}
            setModalFaleConnosco={setModalFaleConnosco}
            allinfoText={allinfoText}
            ficha={ficha}
            modalFaleConnosco={modalFaleConnosco}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        </>
      )}
    </>
  );
}
